<template>

  <el-dialog :title="this.mapped == 0?'创建映射':'修改映射'"
             :close-on-click-modal="false"
             :visible.sync="visible"
             v-if="visible"
             @closed="visible=false"
             @close="handleCancle">
    <div>
      <el-form label-width="1px"
               :model="form" >
        <el-form-item>
          <div class="block">
            <el-cascader :options="projectOptions"
                         :props="levelData"
                         v-model="form.level"
                         placeholder="单选选择任意一级选项"
                         clearable
                         ref="cascade"
                         @change="clearItem"
            ></el-cascader>
          </div>
        </el-form-item>
        <el-table
            :data="dataForm"
            style="width: 100%">
          <el-table-column
              label="字段"
              width="230">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.manualEntryFieldName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="数据项" >
            <template slot-scope="scope">
              <el-select  placeholder="请选择数据项" v-model="scope.row.name" @change="selectionChange(scope)" filterable allow-create default-first-option>
                <el-option
                    v-for="(item,index) in dataItemList"
                    :key="item.measurePointId"
                    :label="item.name"
                    :value="item.measurePointId">
                </el-option>
              </el-select>
            </template>
          </el-table-column>

          <!-- <el-table-column v-if="this.newMapped == 1">
            <el-tag type="warning" :v-if="this.newMapped == 1">未选择</el-tag>
          </el-table-column> -->

        </el-table>
        <el-button type="primary" @click="mappingDataToDataBase()">{{ this.mapped == 0?'确定':'修改' }}</el-button>
      </el-form>
    </div>
  </el-dialog>

</template>

<script>
Array.prototype.indexOf = function(val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == val) {
      return i;
    }
  }
  return -1;
};
Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};
String.prototype.hashCode = function() {
  var hash = 0, i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr   = this.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export default {
  props:{
    manualEntryFieldMappingId:{
      type:Number,
      required:true
    },
    mapped:{
      type:Number,
      required:true
    }

  },
  data() {
    const self = this
    return {
      dataItemList: [],
      projectOptions: [],
      levelData: {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          self.getSecond(node, resolve);
        }
      },
      form: {
        fileId: "",
        fileName: "",
        bucket: "",
        fileDir: "",
        prefix: "",
        level: [],

        //不需要的
        Coal: '',
        System: '',
        SubSystem: '',
        MainDevice: '',
        SubDevice: '',
      },



      //分隔，上面为新加的
      newManualEntryFieldMappingId:0,
      coalCount:0,
      newMapped:0,
      visible: false,
      code:'',
      fileList: [],
      //存数据项名字的
      dic: [],
      fieldTypes1:[

      ],
      fieldTypes2:[

      ],
      fieldTypes3:[
      ],
      fieldTypes4:[
      ],
      fieldTypes5:[

      ],
      fieldTypes6:[

      ],
      dataForm:[

      ],
      preValue:[
      ],
      mappedField:[

      ],
      num: [],
      flag : {},
      subEquipmentIdByEdit: 0,
    };
  },
  created(){
    this.newMapped=this.mapped;
  },
  mounted() {
    console.log("mounted");
  },
  methods: {
    handleCancle(){
      this.form= {
        fileId: "",
            fileName: "",
            bucket: "",
            fileDir: "",
            prefix: "",
            // level: {},
            //存的是上方value数组
            // level: [4,1,1],
            level: [],
      }

      //分隔，上面为新加的
      // this.newManualEntryFieldMappingId=0
      },

    getProjects(){
      this.postRequest("/levelManage/projectattributes/getAllProject").then(response => {
        this.projectOptions=[];
        let data=response.data.data
        for(let i=0;i<data.length;i++)
        {
          let o={};
          o.label=data[i].name;
          o.value=data[i].id;
          if(data[i].disabled==0)
            o.leaf=true;
          else o.leaf=false;
          o.project=true
          this.projectOptions.push(o);
        }
        this.visible = true;
      })
    },
    getSecond(node, resolve) {
      this.dataItemList=[]
      if(node.data.leaf ==='是'){
        node.data.leaf = true
      }else {
        node.data.leaf = false
      }
      //叶子节点不进行搜索
      if(node.data.leaf===true){
        resolve([])
        return
      }
      //如果是点击project进来的，搜索第二层级
      if(node.data.project===true){
        let nodes=[];
        this.$http({
          url: this.$http.adornUrl('/levelManage/projectattributes/projectToLevel?id='+node.data.value),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0)
          {
            if(data.state===0)
            {
              this.$message({
                message: '当前项目没有绑定层级关系',
                type: 'success',
              })
            }
            else
            {
              // this.getRequest("/universaltable/getRootAllItem?id=" + node.data.value).then(response => {
              this.getRequest("/universaltable/getRootLevel?id=" + node.data.value).then(response => {
                //第二层非叶子
                let data =response.data.data;
                for(let i=0;i<data.length;i++)
                {
                  let o={};
                  o.label=data[i].name;
                  //每层id
                  o.value=data[i].id;
                  if(data[i].is_leaf ==='是'){
                    data[i].is_leaf = true
                  }else {
                    data[i].is_leaf = false
                  }
                  o.leaf=data[i].is_leaf;
                  o.levelId=response.data.levelId
                  nodes.push(o);
                }
              }).then(()=>{
              this.dataItemList = []
              this.getRequest("/universaltable/getRootLeafLevel?id=" + node.data.value).then(response => {
              if (response) {
              //第二层叶子
              let arr = []
              arr = response.data.data;
              let levelIdItem = response.data.levelId

              for(let i=0;i<arr.length;i++){
                let measurePointId = levelIdItem + '_' +arr[i].id
                arr[i].measurePointId = measurePointId
              }
              this.dataItemList = arr
            }
          });
        }).then(()=>{
          resolve(nodes);
        })
      }
    } else {
      this.$message.error(data.msg)
    }
  })
      }
      //其他搜索第三层级
      else{
        let nodes=[];
        this.getRequest("/universaltable/getSonLevel?id=" + node.data.value+"&tableId="+node.data.levelId).then(response => {
          //第三层非叶子
          let data =response.data.data;
          for(let i=0;i<data.length;i++)
          {
            let o={};
            o.label=data[i].name;
            o.value=data[i].id;
            if(data[i].is_leaf ==='是'){
              data[i].is_leaf = true
            }else {
              data[i].is_leaf = false
            }
            o.leaf=data[i].is_leaf;
            o.levelId=response.data.levelId
            nodes.push(o);
          }
        }).then(()=>{

          this.getRequest("/universaltable/getOtherLeafItem?id=" + node.data.value+"&tableId="+node.data.levelId).then(response => {
            //第三层叶子
            if (response) {
              let arr = []
              arr = response.data.data;
              let levelIdItem = response.data.levelId

              for(let i=0;i<arr.length;i++){
                let measurePointId = levelIdItem + '_' +arr[i].id
                arr[i].measurePointId = measurePointId
                // - this.$set(原数组, 索引值, 需要赋的值)
                //每个对象里加一个叫tableData2的空数组
                // this.$set(arr[i],'measurePointId',measurePointId);

              }
              // for (let i in this.dataForm)
              // {
              //   this.dataForm[i].name='';
              // }
              this.dataItemList = arr
            }
          })

        }).then(()=>{
          resolve(nodes);
        })
      }
      this.$forceUpdate()
    },

    //分隔，上面为新加的
    onClosed() {
      Object.assign(this.$data, this.$options.data())
      this.$refs[formRef].resetFields();
      this.$data = this.$options.data();
    },
    init(id,mapped) {
      this.mapped=mapped;
      this.getProjects();
      this.manualEntryFieldMappingId = id;
      this.dataForm=[];
      this.visible = true;
      this.dataItemList = []
      this.getData();
      for (var i = 0; i < this.dataForm.length; i ++ )
      {
        this.flag[i] = false;
      }
    },

    isRepeat(arr) {
      var hash = {}
      for (var i in arr) {
        if (hash[arr[i]]) { return true }
        hash[arr[i]] = true
      }
      return false
    },
    getData(){
      this.getRequest(
          "/manualEntry/getFieldInfo/?manualEntryId=" +
          this.manualEntryFieldMappingId
      ).then((resp) => {
        if (resp) {
          //加载下方字段信息
          this.dataForm = resp.data;
        }
      });
      //mapper=1是修改
      if (this.mapped == 1) {
        this.getRequest(
            "/manualEntry/selectMappedFields?id=" +
            this.manualEntryFieldMappingId
        ).then((resp) => {
          if (resp) {
            console.log(resp)
            this.preValue = resp.data.data;
            this.dataForm = this.preValue;
            let arr = []
            arr = resp.data.option.data;
            let levelIdItem = resp.data.levelId

            for(let i=0;i<arr.length;i++){
              let measurePointId = levelIdItem + '_' +arr[i].id
              arr[i].measurePointId = measurePointId
            }
            this.dataItemList = arr
          }
        });
      }
    },
    getDisable(value)
    {
      if (this.dic.indexOf(value) >= 0) {
        return true;
      } else {
        return false;
      }

    },
    selectionChange(scope){
      //点击最后一层数据项，传入value值，即为测点id
      console.log(scope)
    },
    mappingDataToDataBase(){
      var flag = 1
      if (this.isRepeat(this.dic))
      {
        this.$message({
          type: "error",
          message: "请勿选择相同数据项！",
        });
        return;
      }
      if (this.form.level === [])
      {
        this.$message({
          type: "error",
          message: "请选择全部字段！",
        });
        return;
      }
      for (var i = 0 ; i < this.dataForm.length; i ++ )
      {
        if (this.dataForm[i].name == "")
        {
          this.$message({
            type: "error",
            message: "请选择全部字段！",
          });
          return;
        }
      }
      if (flag == 1) {
        if (this.mapped == 0) {
          this.$confirm('您确定创建映射吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            //手动录入表id
            let manualEntryId = this.manualEntryFieldMappingId;
            let outData=[];
            for(let i=0;i<this.dataForm.length;i++){
              let o={}
              o.manualEntryId=manualEntryId;
              o.manualEntryFieldId=this.dataForm[i].id
              o.levelId=parseInt(this.dataForm[i].name.split("_")[0])
              o.rowId=parseInt(this.dataForm[i].name.split("_")[1])
              outData.push(o)
            }
            this.postRequest("/manualEntry/updateFieldMappingState?id="+ this.manualEntryFieldMappingId).then((resp) => {
              if(resp){
                this.postRequest("/manualEntry/addMapping",outData).then((resp) => {
                  if (resp) {
                    this.$message({
                      type: "success",
                      message: "映射成功",
                    });
                    this.$emit('update', true)
                    this.visible = false
                  }
                })
              }
            })
          })
        }
        else {
          this.$confirm('您确定修改映射吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let manualEntryId = this.manualEntryFieldMappingId;
            let outData=[];
            for(let i=0;i<this.dataForm.length;i++){
              let o={}
              o.manualEntryId=manualEntryId;
              o.manualEntryFieldId=this.dataForm[i].id
              o.levelId=parseInt(this.dataForm[i].name.split("_")[0])
              o.rowId=parseInt(this.dataForm[i].name.split("_")[1])
              outData.push(o)
            }
            this.postRequest("/manualEntry/updateFieldMappingState?id="+ this.manualEntryFieldMappingId).then((resp) => {
              if(resp){
                this.postRequest("/manualEntry/addMapping",outData).then((resp) => {
                  if (resp) {
                    this.$message({
                      type: "success",
                      message: "映射成功",
                    });
                    this.$emit('update', true)
                    this.visible = false
                  }
                })
              }
            })
          })
        }
      }
      else {
        this.$message({
          type: "error",
          message: "请选择全部字段",
        });
      }
    },
    clearItem(){
      for(let i=0;i<this.dataForm.length;i++){
        this.dataForm[i].measurePointId=null;
        this.dataForm[i].name=null;
      }
    }

  },
};
</script>
<style scoped>
.el-input {
  margin-right: 10px;
}
.el-select {
  width: 250px;
  margin-right: 10px;
}
.warningButton{
  color: #F56C6C;
}
.addButton{
  margin-bottom: 15px;
  margin: 0 auto;
  text-align: center;
}
</style>